export function handleExceptions( si ) {

	//	otazka D5

	//	podotazka d5_1

	var d5_1_all = [ "d5_1a1", "d5_1a2", "d5_1a3", "d5_1a4", "d5_1a5" ];
	var d5_1_one = "d5_1a6";
	if ( d5_1_all.indexOf( si) > -1 ) {
		var all = true;
		for( var i in d5_1_all  ) {
			if ( document.querySelector('[name='+d5_1_all[i]+']').checked )
				all = false;
		}
		if ( all ) enableElement ( d5_1_one );
		else deselectElement( d5_1_one, false );
	}
	if ( si == d5_1_one ) {

		if ( document.querySelector('[name='+si+']').checked ) {
			for ( var e in d5_1_all) {
				deselectElement( d5_1_all[e] )
			}
		} else {
			for ( var e in d5_1_all) {
				enableElement( d5_1_all[e] )
			}
		}
	}

	//	podotazka d5_2

	var d5_2_all = [ "d5_2a1", "d5_2a2", "d5_2a3" ];
	var d5_2_one = "d5_2a4";
	if ( d5_2_all.indexOf( si) > -1 ) {
		var all = true;
		for( var i in d5_2_all  ) {
			if ( document.querySelector('[name='+d5_2_all[i]+']').checked )
				all = false;
		}
		if ( all ) enableElement ( d5_2_one );
		else deselectElement( d5_2_one, false );
	}
	if ( si == d5_2_one ) {

		if ( document.querySelector('[name='+si+']').checked ) {
			for ( var e in d5_2_all) {
				deselectElement( d5_2_all[e] )
			}
		} else {
			for ( var e in d5_2_all) {
				enableElement( d5_2_all[e] )
			}
		}
	}

	//	otazka E1

	var e1_all = [ "e1_1_1a", "e1_1_2a", "e1_2a", "e1_3a", "e1_4a", "e1_5a" ];
	var e1_one = "e1a1";

	if ( si == e1_one ) {
		for ( var e in e1_all) {
			if ( document.querySelector('[name='+si+']').checked ) deselectRadio( e1_all[e] );
			else enableRadio( e1_all[e] );
		}
	}

	//	otazka K2

	//var k2_all = [ "k2_3_1a", "k2_3_2a", "k2_3_3a", "k2_3_4a" ];
	var k2_all = [ "k2_3_1a1", "k2_3_1a2", "k2_3_1a3", "k2_3_1a4", "k2_3_2a1", "k2_3_2a2", "k2_3_2a3", "k2_3_3a1", "k2_3_3a2", "k2_3_4a1", "k2_3_4a2" ];
	var k2_one = "k2_3a1";

	if ( si == k2_one ) {
		for ( var k2 in k2_all) {
			if ( document.querySelector('[name='+si+']').checked ) deselectElement( k2_all[k2] );
			else enableElement( k2_all[k2] );
		}
	}
}

function deselectElement( si ) {
	//console.log( si );
	document.querySelector('[name='+si+']').checked = false;
	document.querySelector('[name='+si+']').disabled = true;
	document.querySelector('[name='+si+']').parentElement.classList.add("disabled");
}

function enableElement( si ) {
	document.querySelector('[name='+si+']').disabled = false;
	document.querySelector('[name='+si+']').parentElement.classList.remove("disabled");
}

function deselectRadio( si ) {
	//console.log( si );
	
	var all = document.querySelectorAll('[name='+si+']');
	for ( var i=0; i<all.length; i++ ) {
		all[i].checked = false;
		all[i].parentElement.classList.add("disabled");
		all[i].disabled = true;
	}
	if (document.querySelector('.table')) {
		document.querySelector('.table').classList.add("disabled");
	}
	
}

function enableRadio( si ) {
	if (document.querySelector('.table')) {
		document.querySelector('.table').classList.add("disabled");
	}
	var all = document.querySelectorAll('[name='+si+']');
	for ( var i=0; i<all.length; i++ ) {
		all[i].disabled = false;
		all[i].parentElement.classList.remove("disabled");
	}	
	if (document.querySelector('.table')) {
		document.querySelector('.table').classList.remove("disabled");
	}
}