import React, { useContext, useState } from "react";
import { LastAnswersContext } from "../stage/loginPage/LastQuestionsContext";
import Checkbox from "./Checkbox";
import Input from "./Input";

function InputCheckboxString(props) {
  const [checked, setChecked] = useState(false);
  const [value, setValue] = useState("");
  const [textValue, setTextValue] = useState("");
  const handleOnChange = (e) => {
    // console.log(e.target.checked);
    setChecked(e.target.checked);
    if (checked == false) {
      setValue(e.target.value);
      setTextValue(e.target.value);
    } else {
      setValue("");
    }
    props.onChange();
  };
  const lastAnswers = useContext(LastAnswersContext);

  if (
    !checked &&
    lastAnswers.some(a => a.answers.answerId === props.id)
  ) {
    setChecked(true)
  }

  // console.log('→', lastAnswers, props);

  return (
    <div>
      <Checkbox
        name={props.name}
        checked={checked}
        onChange={handleOnChange}
        value={props.value}
        id={props.id}
      >{props.children}</Checkbox>
      {checked ? (
        <Input
          className="text-showed"
          id={"text_" + props.id}
          onChange={props.onChange}
        ></Input>
      ) : (
        ""
      )}
    </div>
  );
}
export default InputCheckboxString;
