// @ts-check
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./Investicni-dotaznik.css";
import Header from "./../../components/Header";
import Progress from "./../../components/Progress";
import Button from "../../components/Button";
import InputRadioWrapper from "../../components/InputRadioWrapper";
import InputRadioStringWrapper from "../../components/InputRadioStringWrapper";
import InputCheckbox from "../../components/InputCheckbox";
import InputCheckboxString from "../../components/InputCheckboxString";
import InputCheckboxDropdown from "../../components/InputCheckboxDropdown";
import Input from "../../components/Input";
import InputRadio from "../../components/InputRadio";
import IdleTimeOutHandler from "../../components/IdleTimeOutHandler";
import { validateQuestion } from "../../functions/validation";
import ProgressCircle from "../../components/ProgressCircle.js";
import { LastAnswersContext } from "../loginPage/LastQuestionsContext";
import { actionOnResponseStatus } from "../../functions/actionOnResponseStatus";

function InvesticniDotaznik({ tokenUrl, sessionId, setLastAnswers, loading, setLoading, showLogInTest }) {
  const navigate = useNavigate();
  const lastAnswers = useContext(LastAnswersContext);
  let _lastAnswers = [];
  const [dataAllSections, setDataAllSections] = useState(null);
  const [dataActiveSection, setDataActiveSection] = useState({ questions: [] });
  const [questionaireFinished, setQuestionaireFinished] = useState(false);
  const [inputsCheckedValue, setInputsCheckedValue] = useState([]);
  const [expired, setExpired] = useState(false);
  // ukaz otazku
  const [visibleQuestion, setVisibleQuestion] = useState(0);
  const [questOfActualSecLength, setQuestOfActualSecLength] = useState(0);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  // const [disablePrevBtn, setDisablePrevBtn] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [inputError, setInputError] = useState([]);
  const [errorText, setErrorText] = useState("");
  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);

  function includesAnyOfAnswerId(knownIds, treeOrItem) {
    var str = JSON.stringify(treeOrItem);
    return knownIds.some((id) => -1 != str.indexOf(id));
  }

  const handleInputChange = (event) => {
    document.querySelectorAll(".error").forEach((error) => {
      error.classList.remove("error");
    });
    document.querySelectorAll(".error-text").forEach((error) => {
      error.remove();
    });
    setErrorText("");

    let arr;
    if (!event || !event.target) {
      console.warn("handleInputChange bez eventu");
      return;
    }
    if (event.target.checked) {
      setInputsCheckedValue((values) => {
		let i = values.indexOf("k1a2");
		if (i>-1) values.splice(i,1);
        arr = [...values, event.target.value];
        return arr;
      });
    } else {
      removeInputChecked(event);
    }
  };

  const removeInputChecked = (e) => {
    setInputsCheckedValue(inputsCheckedValue.filter((id) => id !== e.target.value));
  };
  function getLastQuestions(OKCallback) {
    fetch(window["INVEST-FORM-API-BASE"] + "get-answers", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((answers) => {
        if (answers.responseStatus === "OK" && answers.result) {
          _lastAnswers = answers.result;
          setLastAnswers(answers.result);
          // console.log('received last answers', answers.result);
        } else {
          throw Error("Last answers error.");
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // ono kdyby tohle selhalo, tak se tak moc neděje
        OKCallback();
      });
  }

  useEffect(() => {
    getLastQuestions(hasNextSection);
  }, []);

  // nacte all sections
  const fetchDataAllSections = async () => {
    const res = await fetch(window["INVEST-FORM-API-BASE"] + "get-all-sections", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    });
    const json = await res.json();
    showLogInTest("all", json);
    if (json.responseStatus === "OK") {
      if (json?.result?.value) {
        setDataAllSections(json.result.value);
        fetchDataActiveSection();
      } else {
        setTimeout(fetchDataAllSections, 1000);
      }
    } else {
      actionOnResponseStatus(json.responseStatus);
    }
  };
  // pta se na dalsi sekci
  var fetchCounter = 1;
  var fetchInterval;
  const hasNextSection = async () => {
    if (!tokenUrl || !sessionId) {
      throw Error("nemá smysl se ptát na sekci, když nemáme sessionID");
    }
    if (fetchCounter < 10) {
      fetchInterval = 1000;
    }
    else if (fetchCounter < 20) {
      fetchInterval = 2000;
    }
    else if (fetchCounter <= 30) {
      fetchInterval = 3000;
    } else {
      navigate('/technical-issue');
      return;
    }
    const res = await fetch(window["INVEST-FORM-API-BASE"] + "has-next-section", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    });
    const json = await res.json();
    showLogInTest("has next", json);
    if (json.responseStatus === "OK") {
      switch (json?.result) {
        case true:
          fetchDataAllSections();
          break;
        case false:
          endSession();
          setQuestionaireFinished(true);
          break;
        default:
          setTimeout(hasNextSection, fetchInterval);
          fetchCounter++;
          break;
      }
    } else {
      actionOnResponseStatus(json.responseStatus);
    }
  };
  const endSession = async () => {
    const res = await fetch(window["INVEST-FORM-API-BASE"] + "end-session", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    });
    const json = await res.json();
    showLogInTest("end session", json);
  };

  // nacte active sections
  const fetchDataActiveSection = async () => {
    fetch(window["INVEST-FORM-API-BASE"] + "get-active-section", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.responseStatus === "OK") {
          setTimeout(() => {
            setDataActiveSection(data.result?.value);
            showLogInTest("active", data);
            var stepIndex = 0;
            var lastAnswersIds = _lastAnswers.map((e) => e.answers.answerId);
            data.result?.value.questions.forEach((step, index) => {
			step.subquestions.forEach( (itm,ind) => {
				itm.subquestions.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
			});
			// console.log('!!!', step, lastAnswersIds);
              if (includesAnyOfAnswerId(lastAnswersIds, step)) {
                // console.log('YES!', step.sequenceNumber, index, stepIndex)
                if (step.sequenceNumber > stepIndex) {
                  // sequenceNumber je od 1, tj bude to právě ta další
                  stepIndex = index + 1;
                }
              } else {
                // console.log('NO!', step.sequenceNumber, index, stepIndex)
              }
            });
            setLoading(false);
            // alert(JSON.stringify(lastAnswersIds));
            setQuestOfActualSecLength(data.result.value?.questions?.length);
            setVisibleQuestion(stepIndex);
            // delayedNextButtonValidation(data.result.value?.questions[stepIndex]);
          }, 1000);
        } else {
          actionOnResponseStatus(data.responseStatus);
        }
      });
  };

  const NextStep = () => {
    document.querySelectorAll(".error").forEach((error) => {
      error.classList.remove("error");
    });
    document.querySelectorAll(".error-text").forEach((error) => {
      error.remove();
    });
    setLoading(true);
    setDisableNextBtn(true);
    const currentStep = dataActiveSection?.questions[visibleQuestion];
    // validace
    var listErrors = validateQuestion(currentStep);
    // hotfix pro "tabulku" (4. oddíl 1. krok)
    if (listErrors.length && currentStep.subversionIdentificator === "e1") {
      let noExpInp = document.querySelector('[name="e1a1"]');
      if (listErrors.length <= 18) {
        noExpInp?.parentElement?.classList.add("error");
        if (noExpInp.checked) {
          // console.log('ignorovat ostatní chyby pro zaškrtlém "nemám zkušenosti"');
          noExpInp?.parentElement?.classList.remove("error");
          listErrors = [];
        }
        if (!noExpInp.checked && document.getElementById(listErrors[0]) === noExpInp) {
          // console.log('ignorovat "nemám zkušenosti" při validní tabulce');
          noExpInp?.parentElement?.classList.remove("error");
          listErrors = [];
        }
      }
    }
    /*if (listErrors.length && currentStep.subversionIdentificator === "k2") {
      let noExpInp = document.querySelector('[name="k2_3a1"]');
      if (listErrors.length <= 23) {
        noExpInp?.parentElement?.classList.add("error");
        if (noExpInp.checked) {
          // console.log('ignorovat ostatní chyby pro zaškrtlém "nemám zkušenosti"');
          noExpInp?.parentElement?.classList.remove("error");
          listErrors = [];
        }
        if (!noExpInp.checked && document.getElementById(listErrors[0]) === noExpInp) {
          // console.log('ignorovat "nemám zkušenosti" při validní tabulce');
          noExpInp?.parentElement?.classList.remove("error");
          listErrors = [];
        }
      }
    }*/
    var answer, textInput, wrapper;

    if (listErrors.length !== 0) {
      listErrors.forEach((id) => {
        document.getElementById(id)?.parentNode?.classList.add("error");
      });
      for (let index = 0; index < listErrors.length; index++) {
        answer = document.getElementById(listErrors[index]);
        wrapper = answer?.parentElement;
        textInput = document.getElementById("text_" + listErrors[index]);
        setErrorText("Vyberte prosím minimálně jednu z možností");
        wrapper.scrollIntoView();
        var el = document.createElement("div");
        el.classList.add("error-text");
        el.classList.add("error-string");
        wrapper?.classList.add("error");
        var table = document.querySelector(".table");
        table?.classList.add("table-error");
        var subanswers = wrapper.nextElementSibling;
        if (subanswers && currentStep.subversionIdentificator !== "e1") {
          wrapper?.classList.add("error");
          el.innerText = "Vyberte prosím minimálně jednu z možností";
          setErrorText("");
          subanswers.after(el);
        }
        if (textInput) {
          setErrorText("");
          el.innerText = "Vyplňte prosím toto pole";
          textInput.after(el);
        }
      }
      setLoading(false);
      setDisableNextBtn(false);
    } else {
      // if (!window.confirm('Validní. Uložit a pokračovar?')) {
      //   return
      // };
      var selected = fillAnswerObject(currentStep);
      fetch(window["INVEST-FORM-API-BASE"] + "set-answer", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          token: tokenUrl,
          sessionId: sessionId,
          answer: selected,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          showLogInTest("set answer", data);
          if (data.responseStatus === "OK") {
            setInputsCheckedValue([]);
            setErrorText("");
            saveSection();
          } else {
            actionOnResponseStatus(data.responseStatus);
          }
        });
    }

    function saveSection() {
      if (visibleQuestion === questOfActualSecLength - 1 || inputsCheckedValue.includes("e1a1") || inputsCheckedValue.includes("k1a2")) {
        fetch(window["INVEST-FORM-API-BASE"] + "save-section-answers", {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            token: tokenUrl,
            sessionId: sessionId,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            showLogInTest("save section", data);
            if (data.responseStatus === "OK") {
              hasNextSection();
            } else {
              actionOnResponseStatus(data.responseStatus);
            }
          });
      } else {
        setDisableNextBtn(dataActiveSection.questions[visibleQuestion + 1]);
        setVisibleQuestion(visibleQuestion + 1);
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        setLoading(false);
      }
    }
  };

  const fillAnswerObject = (aktualquestion) => {
    var answers = [];
    var aktualSubQuestion = aktualquestion.subquestions;

    if (aktualSubQuestion && aktualSubQuestion.length > 0) {
      for (var i = 0; i < aktualSubQuestion.length; i++) {
        var adding = fillAnswerObject(aktualSubQuestion[i]);
        answers = answers.concat(adding);
      }
    }
    for (var i = 0; i < aktualquestion.answers.length /*|| i < aktualSubQuestion.answers.length*/; i++) {
      var answer = aktualquestion.answers[i];
      // selectAnswer;
      if (answer.answerType === "String") {
        if (document.getElementById(answer.answerId).value.trim() !== "") {
          answers.push({
            questionId: aktualquestion.questionId,
            answerId: answer.answerId,
            note: document.getElementById(answer.answerId).value.trim(),
          });
        }
      }
      if (answer.answerType === "Radio" || answer.answerType === "Checkbox") {
        if (document.getElementById(answer.answerId).checked) {
          if (answer.subAnswers) {
            for (var a = 0; a < answer.subAnswers.length; a++) {
              var subAnswer = answer.subAnswers[a];
              // console.log(subAnswer);
              if (document.getElementById(subAnswer.answerId).checked) {
                // console.log(subAnswer);
                if (subAnswer.answerType === "CheckboxString") {
                  // console.log(
                  //   document.getElementById("text_" + subAnswer.answerId).value
                  // );
                  answers.push({
                    questionId: aktualquestion.questionId,
                    answerId: subAnswer.answerId,
                    note: document.getElementById("text_" + subAnswer.answerId).value,
                  });
                } else {
                  answers.push({
                    questionId: aktualquestion.questionId,
                    answerId: subAnswer.answerId,
                    note: "",
                  });
                }
              }
            }
          } else {
            answers.push({
              questionId: aktualquestion.questionId,
              answerId: answer.answerId,
              note: "",
            });
          }
        }
      }
      if (answer.answerType === "RadioString" || answer.answerType === "CheckboxString") {
        if (document.getElementById(answer.answerId).checked) {
          answers.push({
            questionId: aktualquestion.questionId,
            answerId: answer.answerId,
            note: document.getElementById("text_" + answer.answerId).value,
          });
          if (document.getElementById("text_" + answer.answerId).value === "") {
            // console.log(answers.note);
          }
        }
      }
      if (answer.answerType === "CheckboxDropdown") {
        if (document.getElementById(answer.answerId).checked) {
          answers.push({
            questionId: aktualquestion.questionId,
            answerId: document.getElementById("select_" + answer.answerId).value,
            note: "",
          });
          var textanswer = "";
          for (var j = 0; j < answer.subAnswers.length; j++) {
            if (answer.subAnswers[j].answerType === "String") {
              textanswer = answer.subAnswers[j].answerId;
              //break;
            }
          }
          answers.push({
            questionId: aktualquestion.questionId,
            answerId: textanswer,
            note: document.getElementById("text_" + answer.answerId).value,
            // note: aktualquestion.,
          });
        }
      }
      if (answer.answerType === "TableCol") {
        if (document.getElementById(answer.answerId).checked) {
          answers.push({
            questionId: aktualquestion.questionId,
            answerId: answer.answerId,
            note: "",
          });
        }
      }
    }
    // console.log("answers", answers);
    return answers;
  };

  // question ID do parametru
  function questionIdIntoUrlParam(idQ) {
    var url = new URL(window.location.href);
    var params = url.searchParams.set("questionId", idQ);
    // if (window.history.pushState) {
    //   var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?question='+idQ;
    //   window.history.pushState({path:newurl},'',newurl);
    //   }
  }

  const renderAnswer = ({
    textCz: textCz,
    answerType: answerType,
    answerId: answerId,
    subversionIdentificator: subversionIdentificator,
    subAnswers: subAnswers,
    answerExclusivity: answerExclusivity,
    checkOverride: checkOverride,
  }) => {
    var ret;
    switch (answerType) {
      case "String":
        ret = (
          <>
            <Input name={subversionIdentificator} id={answerId} key={answerId} placeholder={textCz}>
              {textCz}
            </Input>
          </>
        );
        break;
      case "Radio":
        ret = (
          <>
            <InputRadioWrapper
              id={answerId}
              key={answerId}
              value={subversionIdentificator}
              name={subversionIdentificator.substring(0, subversionIdentificator.length - 1)}
              passSubAnswers={subAnswers}
              renderAnswer={renderAnswer}
              onChange={handleInputChange}
              checkedOverride={checkOverride}
            >
              {textCz}
            </InputRadioWrapper>
          </>
        );
        break;
      case "RadioString":
        ret = (
          <>
            <InputRadioStringWrapper
              name={subversionIdentificator.substring(0, subversionIdentificator.length - 1)}
              id={answerId}
              key={answerId}
              value={subversionIdentificator}
              onChange={handleInputChange}
            >
              {textCz}
            </InputRadioStringWrapper>
          </>
        );
        break;
      case "Checkbox":
        ret = (
          <>
            <InputCheckbox
              name={subversionIdentificator}
              id={answerId}
              key={answerId}
              value={subversionIdentificator}
              onChange={handleInputChange}
              passSubAnswers={subAnswers}
              renderAnswer={renderAnswer}
              checkOverride={checkOverride}
              error={inputError}
            >
              {textCz}
            </InputCheckbox>
          </>
        );
        break;
      case "CheckboxString":
        ret = (
          <>
            <InputCheckboxString
              name={subversionIdentificator}
              id={answerId}
              key={answerId}
              value={subversionIdentificator}
              onChange={handleInputChange}
            >
              {textCz}
            </InputCheckboxString>
          </>
        );
        break;
      case "CheckboxDropdown":
        ret = (
          <>
            <InputCheckboxDropdown
              name={subversionIdentificator}
              id={answerId}
              key={answerId}
              value={subversionIdentificator}
              onChange={handleInputChange}
              optionValue={
                subAnswers !== null
                  ? subAnswers.map((item) => {
                      return {
                        answerId: item.answerId,
                        textCz: item.textCz,
                        answerType: item.answerType,
                      };
                    })
                  : []
              }
              renderAnswer={renderAnswer}
            >
              {textCz}
            </InputCheckboxDropdown>
          </>
        );
        break;
      case "DropdownValue":
        ret = (
          <option value={answerId} key={answerId} id={answerId}>
            {textCz}
          </option>
        );
        break;
      default:
        break;
    }
    return <div key={`answer_wrap_${answerId}`}>{ret}</div>;
  };
  let colAnswers = 0;

  if (expired) return <Navigate to="/" replace={true}></Navigate>;
  else
    return (
      <div className="dotaznik">
        <IdleTimeOutHandler
          onActive={() => {
            setExpired(false);
          }}
          onIdle={() => {
            setExpired(true);
          }}
        />
        <Header></Header>
        <div className="container cont-col">
          <Progress
            dataAllSections={dataAllSections}
            dataActiveSection={dataActiveSection}
            questionaireFinished={questionaireFinished}
          ></Progress>
          <div className="container column">
            {questionaireFinished ? (
              <div className="thank-you-page main main--thank-you">
                <div className="thank-you-page__done"></div>
                <h2 className="thank-you-page__h2">Děkujeme, že jste vyplnili svůj investiční dotazník.</h2>
                <p className="thank-you-page__p">Své odpovědi najdete brzy ve svém e-mailu.</p>
                <p className="thank-you-page__p">
                  Přejeme Vám mnoho úspěšných investičních rozhodnutí.
                  <br />S důvěrou se na nás můžete kdykoli obrátit.
                </p>
              </div>
            ) : (
              <form className={`dotaznik__form ${loading ? "no-click" : ""}`}>
                {dataActiveSection?.questions.length ? (
                  <div className="main mw800 section">
                    {dataActiveSection.questions.map(
                      (
                        {
                          textCz: textCzQ,
                          answers: ans,
                          subversionIdentificator: identificator,
                          descriptionCz: hintText,
                          questionId: idQ,
                          subquestions: subquestions,
                          picture: picture,
                          type: type,
                        },
                        index,
                        { length: lengthQuestions }
                      ) => {
                        return (
                          <div
                            className={`dotaznik__question ${visibleQuestion === index ? "" : "d-none"}`}
                            id={idQ}
                            key={idQ}
                          >
                            <div className="dotaznik__header">
                              <h2 className="dotaznik__header__h2">{dataActiveSection.nameCz.substring(3)}</h2>
                              <div className="dotaznik__header__divider"></div>
                              <div className="dotaznik__header__question">
                                Otázka <span>{++index}</span>/{lengthQuestions}
                              </div>
                            </div>
                            <h3 className="question" id={idQ} key={idQ}>
                              {questionIdIntoUrlParam(idQ)}
                              {textCzQ}
                              {hintText !== null ? (
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="question-mark"
                                >
                                  <circle cx="8" cy="8" r="5.5" stroke="#757575" />
                                  <path
                                    d="M6.38402 7.10526C6.2998 6.93684 6.2998 6.76842 6.2998 6.6C6.2998 5.67368 7.0577 5 7.8998 5C8.74191 5 9.4998 5.75789 9.4998 6.6C9.4998 7.44211 8.82612 8.2 7.8998 8.2V9.63158"
                                    stroke="#757575"
                                    strokeMiterlimit="10"
                                    strokeLinejoin="bevel"
                                  />
                                  <line x1="7.97852" y1="10.4736" x2="7.97852" y2="11.3157" stroke="#757575" />
                                </svg>
                              ) : (
                                ""
                              )}
                            </h3>
                            {picture !== null ? (
                              <img src={`data:image/jpeg;base64, ${picture}`} className="json-picture" />
                            ) : null}
                            {ans.map(renderAnswer)}
                            {type === "TableParent" && screenWidth >= 800 ? (
                              <div className="table-div">
                                <table className="table">
                                  <thead>
                                    <tr key={"thead_1"}>
                                      <th rowSpan={2} className="sticky-th">
                                        Zkušenosti s investováním
                                      </th>
                                      {subquestions
                                        ? subquestions.map(
                                            ({
                                              textCz: textCz,
                                              answers: answers,
                                              questionId: questionId,
                                              subquestions: subquestions,
                                              type: type,
                                            }) => {
                                              colAnswers = answers.length;
                                              return (
                                                <th
                                                  rowSpan={!subquestions.length ? 2 : 1}
                                                  colSpan={subquestions.length ? 2 : 1}
                                                  key={textCz}
                                                >
                                                  {textCz}
                                                </th>
                                              );
                                            }
                                          )
                                        : null}
                                    </tr>
                                    <tr key={"thead_2"}>
                                      {subquestions
                                        ? subquestions.map(
                                            ({
                                              subquestions: subquestionsS,
                                            }) => {
                                              return subquestionsS
                                                ? subquestionsS.map(
                                                    (
                                                      {
                                                        textCz: textCz,
                                                        answers: answers,
                                                        questionId: questionId,
                                                        subquestions: subquestions,
                                                        type: type,
                                                      },
                                                      i
                                                    ) => {
                                                      return <th key={"thead_2_" + i}>{textCz}</th>;
                                                    }
                                                  )
                                                : null;
                                            }
                                          )
                                        : null}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {[0, 1, 2].map((rowIndex) => {
                                      return (
                                        <tr key={"tbody_row_" + rowIndex}>
                                          <td className="sticky-td" key={"tbody_row_" + rowIndex + "_first"}>
                                            {subquestions[2].answers[rowIndex].textCz}
                                          </td>
                                          {subquestions
                                            ? subquestions.map(
                                                ({
                                                  textCz: textCz,
                                                  answers: answers,
                                                  questionId: questionId,
                                                  subquestions: subquestions,
                                                  subversionIdentificator: subversionIdentificator,
                                                }) => {
                                                  return subquestions.length ? (
                                                    subquestions.map((subQ) => (
                                                      <td key={`td_${subQ.subversionIdentificator}`}>
                                                        <InputRadio
                                                          className="radio-table"
                                                          name={subQ.subversionIdentificator + "a"}
                                                          onChange={handleInputChange}
                                                          id={subQ.answers[rowIndex].answerId}
                                                        ></InputRadio>
                                                      </td>
                                                    ))
                                                  ) : (
                                                    <td>
                                                      <InputRadio
                                                        className="radio-table"
                                                        name={subversionIdentificator + "a"}
                                                        onChange={handleInputChange}
                                                        id={answers[rowIndex].answerId}
                                                      ></InputRadio>
                                                    </td>
                                                  );
                                                }
                                              )
                                            : null}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            ) : (
                              <div className={`${ dataActiveSection.subversionIdentificator === "k" ? "subquestions-col" : "subquestions-row"}`}>
                                {subquestions
                                  ? subquestions.map(
                                      ({
                                        textCz: textCz,
                                        answers: answers,
                                        questionId: questionId,
                                        subquestions: subquestions,
                                      }) => {
                                        return (
                                          <div className="subquestions-item">
                                            <h4 id={questionId} key={questionId}>
                                              {textCz}
                                            </h4>
                                            {answers.map(renderAnswer)}
                                            {subquestions
                                              ? subquestions.map(({ answers: answers, textCz: textCz }) => {
                                                  return (
                                                    <div>
                                                      <h5 id={questionId} key={questionId}>
                                                        {textCz}
                                                      </h5>
                                                      {answers.map(renderAnswer)}
                                                    </div>
                                                  );
                                                })
                                              : null}
                                          </div>
                                        );
                                      }
                                    )
                                  : null}
                              </div>
                            )}
                            <div id="errorText" className="error-question">
                              {errorText}
                            </div>
                            <div className="button-row">
                              <Button
                                className="button-next"
                                onClick={NextStep}
                              >
                                {loading ? (
                                  <div className="progresscircle-btn">
                                    <ProgressCircle
                                      radius={10}
                                      cx={20}
                                      cy={20}
                                      stroke={"white"}
                                      strokeWidth={3}
                                    ></ProgressCircle>
                                  </div>
                                ) : (
                                  "Pokračovat"
                                )}
                              </Button>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className="progresscircle">
                    <ProgressCircle></ProgressCircle>
                  </div>
                )}
              </form>
            )}
          </div>
        </div>
        <Link to={"/cookies"} className="cookies-link cookies-dotaznik">
          Informace o cookies
        </Link>
      </div>
    );
}
export default InvesticniDotaznik;
