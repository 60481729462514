import React, {useContext, useState} from "react";
import { LastAnswersContext } from "../stage/loginPage/LastQuestionsContext";

function InputRadio(props) {
  const [value, setValue] = useState('');
  // const handleOnChange = (e) => {
  //   console.log(e.target.checked);
  //   setValue(e.target.checked);
  // };
  const lastAnswers = useContext(LastAnswersContext);
  let _checked = props.isChecked;

  // console.log('radio',props,lastAnswers)
  if (!_checked && lastAnswers.some(a => a.answers.answerId === props.id)) {
    // console.log('checked!!!1!');
    _checked = true
  }

  return (
    <>
    <input
      type="radio"
      className={ `radio ${props.className || ""}` }
      name={props.name}
      value={props.value}
      checked={_checked}
      id={props.id}
      onChange={props.onChange}
      // onClick={props.onChange}
    ></input>
    <span className='custom-radio'></span>
    </>
  );
}

export default InputRadio;
