import React from "react";
import "./css/ProgressCircle.css";

function ProgressCircle(props) {
  return (
    <div className="loader">
      <svg className="circular">
        <circle
          className="path"
          cx={props.cx || 50}
          cy={props.cy || 50}
          r={props.radius || 20}
          fill="none"
          stroke={props.stroke || "#00423b"}
          strokeWidth={props.strokeWidth || 5}
          strokeMiterlimit="10"
        ></circle>
      </svg>
    </div>
  );
}
export default ProgressCircle;
