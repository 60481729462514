export function actionOnResponseStatus(responseStatus) {
  switch (responseStatus) {
    case "ERR":
      window.location.replace("/technical-issue");
      break;
    case "WWW_SESSION_EXPIRED":
      window.location.replace("/");
      break;
    case "INVALID_TOKEN":
      window.location.replace("/invalid");
      break;
    case "FORM_EXPIRED":
      window.location.replace("/invalid");
      break;
    case "PROCESS_END":
      window.location.replace("/invalid");
      break;
    default:
      window.location.replace("/technical-issue");
      console.log(responseStatus);
      break;
  }
}
