import React from "react";
import "./css/Progress.css";

function Progress(props) {
  return (
    <div className="progress">
      <ul className="progress__ul">
        {props.questionaireFinished
          ? props.dataAllSections.map((item) => {
              return (
                <li
                  className={`progress__li`}
                  id={item.sectionId}
                  key={item.sequenceNumber}
                >
                  <span className={`progress__dot progress__dot--done`}></span>
                  <span className="progres-text">
                    {item.nameCz.substring(3)}
                  </span>
                </li>
              );
            })
          : props.dataAllSections
          ? props.dataAllSections.map((item, index, length) => {
            return props.dataActiveSection ? (
              <li
                className={`progress__li  ${
                  props.dataActiveSection.sequenceNumber > index
                    ? "progress__li--done"
                    : ""
                } ${
                    props.dataActiveSection.sectionId === item.sectionId
                      ? "active"
                      : ""
                  }`}
                  id={item.sectionId}
                  key={item.sequenceNumber}
                >
                  <span
                    className={`progress__dot ${
                      props.dataActiveSection.sequenceNumber > index
                        ? "progress__dot--done"
                        : ""
                    }`}
                  ></span>
                  <span className="progres-text">
                    {item.nameCz.substring(3)}
                  </span>
                </li>
            ) : null;
            })
          : null}
          {/* : <li className="progresscircle"><ProgressCircle></ProgressCircle></li>} */}
      </ul>
    </div>
  );
}

export default Progress;
