import React from 'react';

// o stavu checked vůči lastQuestions rozhodují volající elementy

function Checkbox (
  {
    name,
    checked,
    onChange,
    value,
    id,
    children,
  }
) {
  return (
    <div className="wrapper-checkbox">
      <input
        type="checkbox"
        className="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        value={value}
        id={id}
      ></input>
      <span className="custom-checkbox">
        <svg
          className="checkmark"
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 4L1.33333 2.66666L4 5.33333L8.66667 0.666664L10 2L4 8L0 4Z"
            fill="white"
          />
        </svg>
      </span>
      <label className="label-checkbox">{children}</label>
    </div>
  )
}
export default Checkbox