import React, { useEffect, useRef, useState } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import "./login-page.css";
import "./../stages.css";
import Button from "../../components/Button";
import Banner from "../../components/Banner";
import Input from "../../components/Input";
import Label from "../../components/Label";
import CountDownTicker from "../../components/CountDownTicker";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ProgressCircle from "../../components/ProgressCircle";
import { actionOnResponseStatus } from "../../functions/actionOnResponseStatus";

function LoginPage({ tokenUrl, saveSessionId, sessionId, setLastAnswers, loading, setLoading, showLogInTest, visibleStep2, setVisibleStep2 }) {
  const navigate = useNavigate();
  // HCaptcha
  const [visibleHCaptcha, setVisibleHCaptcha] = useState(true);
  const [pokracovani, setPokracovani] = useState(false);

  const [tokenCaptcha, setTokenCaptcha] = useState(null);
  const captchaRef = useRef(null);
  // test site-key
  const [siteKey, setSiteKey] = useState("5ea21150-c92f-493c-ac4c-bf8748960c4c");
  useEffect(() => {
    if (document.location.hostname.includes("localhost")) {
      setVisibleHCaptcha(false);
    }
    // reference site-key
    if (document.location.hostname.includes("id.ref")) {
      // setSiteKey("3c039463-d860-44ba-ac0c-32e5caf2ec43");
      setSiteKey("93fd582d-714e-4103-b5bc-43dd71fac602");
    }
    // produkce site-key
    if (document.location.hostname.includes("id.jtbank")) {
      setSiteKey("93fd582d-714e-4103-b5bc-43dd71fac602");
    }
    if (tokenCaptcha) {
      showLogInTest("hCaptcha Token", tokenCaptcha);
      VerifyCaptcha();
    }
  }, [tokenCaptcha]);

  // api
  const [expired, setExpired] = useState(false);
  function StartSession() {
    if (tokenUrl === "") {
      setExpired(true);
    } else {
      fetch(window["INVEST-FORM-API-BASE"] + "start-session", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          token: tokenUrl,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          showLogInTest("start session", data);
          if (data.responseStatus === "OK" && data.result.sessionId) {
            let id = data.result.sessionId;
            sessionId = id;
            saveSessionId(id);
            setPokracovani(true);
            return true;
          } else {
            throw Error("response bez sessionId");
          }
        })
        .catch((err) => {
          setExpired(true);
          console.error(err);
        });
    }
  }
  function VerifyCaptcha() {
    fetch(window["INVEST-FORM-API-BASE"] + "verify-captcha-code", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        code: tokenCaptcha,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        showLogInTest("verify captcha", data);
        if (data.responseStatus === "OK") {
          if (data?.result === "false") {
            window.location.reload();
          } else {
            setVisibleHCaptcha(false);
          }
        } else {
          actionOnResponseStatus(data.responseStatus);
        }
      });
  }

  // Zaktivnit Poslat kod znovu
  const [disabledPoslatZnovu, setDisabledPoslatZnovu] = useState(true);

  // SendSms
  function SendSms() {
    expireSMS(true);
    fetch(window["INVEST-FORM-API-BASE"] + "send-otp-sms", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        showLogInTest("send otp sms", data);
        if (data.responseStatus === "OK") {
          setVisibleStep2(true);
          setDisabledPoslatZnovu(true);
        } else {
          actionOnResponseStatus(data.responseStatus);
        }
      });
  }
  // CheckSms
  const [errorTextLogin, setErrorTextLogin] = useState("");
  const [inputSms, setInputSms] = useState("");
  const [disablePokracovat, setDisablePokracovat] = useState(false);
  const prev = useRef("");
  const SetInputSmsValue = (e) => {
      const tgt = e.target;
      const inVal = tgt.value;
      const result = format(inVal, prev.current);
      tgt.value = result;
      prev.current = result;
      setInputSms(result);
    };
    function format(current, previous) {
      const notNums = /\D/g;
      const danglingDash = /^\d{4}-$/;
      if (current.match(danglingDash)) {
        return current;
      }
      const nums = current.replace(notNums, "");
      if (current.length === 4) {
        if (previous.match(danglingDash)) {
          return nums;
        }
      }
      return nums.replace(/(^\d{4})(-?\d{0,4})?.*$/, "$1-$2");
    }
  const CheckSms = () => {
    let sms = inputSms.replace(/-/g, "");
    if (sms.length === 8) {
      setDisablePokracovat(true);
      fetch(window["INVEST-FORM-API-BASE"] + "check-otp", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          token: tokenUrl,
          sessionId: sessionId,
          smsCode: sms,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.responseStatus === "OK") {
            showLogInTest("check otp", data);
            setLoading(true);
            fetchOtpResult();
          } else {
            actionOnResponseStatus(data.responseStatus);
          }
        });
    }
    if (sms.length < 8) {
      setErrorTextLogin("SMS kód je moc krátký");
    }
    if (sms.length > 8) {
      setErrorTextLogin("SMS kód je moc dlouhý");
    }
  };

  function expireSMS(back) {
    if (back) {
      setErrorTextLogin("");
    } else {
      setErrorTextLogin("Zaslaný SMS kód již není platný, je nutné si zaslat nový.");
    }
  }

  const [redirectToQuest, setRedirectToQuest] = useState(false);

  var fetchCounter = 1;
  var fetchInterval;
  function fetchOtpResult() {
    if (fetchCounter < 10) {
      fetchInterval = 1000;
    } else if (fetchCounter < 20) {
      fetchInterval = 2000;
    } else if (fetchCounter <= 30) {
      fetchInterval = 3000;
    } else {
      navigate("/technical-issue");
      return;
    }

    fetch(window["INVEST-FORM-API-BASE"] + "get-otp-result", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({
        token: tokenUrl,
        sessionId: sessionId,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data.responseStatus === "OK") {
          showLogInTest("get otp result", data);
          if (data.result === null) {
            setTimeout(fetchOtpResult, fetchInterval);
            fetchCounter++;
          } else {
            setLoading(false);
            setDisablePokracovat(false);
            switch (data.result) {
              case "OK":
                setErrorTextLogin("");
                setRedirectToQuest(true);
                break;
              case "NOK":
                setErrorTextLogin("Zadali jste chybný SMS kód. Vyplňte ho prosím ještě jednou.");
                break;
              case "LAST_ATTEMPT":
                setErrorTextLogin(
                  "Znovu jste zadali chybný SMS kód. Při dalším špatném pokusu vám zneplatníme SMS kód, a je nutné si zaslat nový."
                );
                break;
              case "BLOCKED":
                setErrorTextLogin(
                  "Třikrát jste zadali chybný SMS kód, proto jsme vám z bezpečnostních důvodů zneplatnili současný SMS kód, zašlete si prosím nový."
                );
                break;
              case "EXPIRED":
                setErrorTextLogin("Zaslaný SMS kód již není platný, je nutné si zaslat nový.");
                break;
              case "WAIT":
                setErrorTextLogin("");
                break;
            }
          }
        } else {
          actionOnResponseStatus(data.responseStatus);
        }
      });
  }

  useEffect(() => {
    // v okamžiku kdy se vykreslí login page tak je asi opravdu
    // nezbytné nastartovat novou session hlava nehlava
    // console.log(tokenUrl, sessionId, "d", !sessionId);
    // if (!sessionId) {
    StartSession();
    // }
  }, []);

  return (
    <div>
      {expired ? <Navigate to="/invalid" replace={true}></Navigate> : null}
      {redirectToQuest === true ? (
        <Navigate to="/dotaznik" replace={true}></Navigate>
      ) : (
        <div className="page-with-banner">
          <Banner />
          <div className="content">
            <div className="text-width">
              <h3 className="h3--id font12">investiční dotazník</h3>
              <h1 className="login-page__h1">
                Svět investic
                <br />
                už máte téměř na dosah.
              </h1>
              <h2 className="login-page__h2">
                U všeho, co děláme, dbáme na maximální ochranu dat našich klientů.
                <br />
                Proto si prosím připravte svůj mobilní telefon, který jste uvedli ve smlouvě.
              </h2>
              {visibleStep2 ? (
                <div className="login-page--step2">
                  <Label className="login-label">SMS kód *</Label>
                  <div className="flex col500">
                    <div className="mw345">
                      <div className="login-mask">
                        <Input
                          inputmode="numeric"
                          pattern="\d+"
                          maxLength="9"
                          autocomplete="one-time-code"
                          className={`text--login" ${errorTextLogin === "" ? null : "error"}`}
                          placeholder="_ _ _ _ - _ _ _ _"
                          id="testInput"
                          onInput={SetInputSmsValue}
                          value={inputSms}
                        ></Input>
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="lock"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.167 16.5002H2.83366C1.91283 16.5002 1.16699 15.7543 1.16699 14.8335V9.00016C1.16699 8.07933 1.91283 7.3335 2.83366 7.3335H11.167C12.0878 7.3335 12.8337 8.07933 12.8337 9.00016V14.8335C12.8337 15.7543 12.0878 16.5002 11.167 16.5002Z"
                            stroke="#8B8F8E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.66699 7.33333V4.83333V4.83333C3.66699 2.9925 5.15949 1.5 7.00033 1.5V1.5C8.84116 1.5 10.3337 2.9925 10.3337 4.83333V4.83333V7.33333"
                            stroke="#8B8F8E"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <p className="error-text">{errorTextLogin !== "" ? errorTextLogin : ""}</p>
                    </div>
                    <Button className="button-next m0" onClick={CheckSms} disabled={disablePokracovat}>
                      {loading ? (
                        <div className="progresscircle-btn">
                          <ProgressCircle radius={10} cx={20} cy={20} strokeWidth={3} stroke={"white"}></ProgressCircle>
                        </div>
                      ) : (
                        "Pokračovat"
                      )}
                    </Button>
                  </div>
                  <div className="mw345 m48">
                    <span className="d-block">
                      <span className="login-page__span">Platnost:</span>
                      <CountDownTicker
                        handleResend={SendSms}
                        handleExpire={expireSMS}
                        tokenUrl={tokenUrl}
                        sessionId={sessionId}
                        setInputSms={setInputSms}
                      ></CountDownTicker>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="login-page--step1">
                  <p className="login-page__p">
                    Po stisknutí tlačítka Vám na toto číslo pošleme jednorázový kód, který slouží jako klíč ke vstupu do
                    dotazníku.
                  </p>
                  {pokracovani ? (
                    visibleHCaptcha ? (
                      <HCaptcha id="hcap" sitekey={siteKey} onVerify={setTokenCaptcha} ref={captchaRef} />
                    ) : (
                      <div>
                        <Button className="button-next m0" onClick={SendSms}>
                          Získat vstupní kod
                        </Button>
                      </div>
                    )
                  ) : null}
                </div>
              )}
            </div>
            <Link to={"/cookies"} className="cookies-link">
              Informace o cookies
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
export default LoginPage;
