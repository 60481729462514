import React from "react";
import "./Cookies.css";
import Banner from "../../components/Banner";
import Button from "../../components/Button";

function Cookies(props) {
  return (
    <div className="page-with-banner cookies">
      <Banner />
      <div className="content">
        <h1 className="login-page__h1">Nakládání s Cookies</h1>
        <h2 className="login-page__h2">INFORMACE O SPRÁVCI OSOBNÍCH ÚDAJŮ</h2>
        <p className="login-page__p">
          J&amp;T BANKA, a.s., IČO: 471 15 378, se sídlem Sokolovská 700/113a,
          186 00 Praha 8 (dále jen „Banka“) tímto jako správce osobních údajů
          informuje subjekty údajů o zpracování osobních údajů.
        </p>
        <p className="login-page__p">
          V případě dotazů souvisejícími se zpracováním osobních údajů se můžete
          obracet na Pověřence pro ochranu osobních údajů Banky prostřednictvím:
        </p>
        <ul>
          <li>
            e-mailu: <a href="mailto:oou@jtbank.cz">oou@jtbank.cz</a>
          </li>
          <li>
            pošty: Pověřenec pro ochranu osobních údajů, Sokolovská 700/113a,
            186 00 Praha 8.
          </li>
        </ul>
        <h2 className="login-page__h2">CO JSOU TO COOKIES?</h2>
        <p className="login-page__p">
          Soubory cookies jsou malé datové soubory, které se ukládají v
          prohlížeči Vašeho zařízení, když vstoupíte na stránku, a shromažďují
          informace o Vás, o Vašich jednotlivých preferencích nebo Vámi
          používaném zařízení. Tyto informace slouží zejména k usnadnění
          používání internetových stránek, jejich rozvoje a získání přehledu o
          jejich využívaných funkcích.{" "}
        </p>
        <h2 className="login-page__h2">
          JAKÝ PRÁVNÍ TITUL BANKA VYUŽÍVÁ PRO ZPRACOVÁNÍ COOKIES?
        </h2>
        <p className="login-page__p">
          Banka zpracovává technické soubory cookies na základě oprávněného
          zájmu. Ostatní cookies zpracovává pouze na základě Vašeho souhlasu.
          Váš souhlas je dobrovolný, tudíž jej můžete kdykoliv odvolat. Své
          cookies si můžete kdykoliv přenastavit podle svých preferencí v sekci
          nastavení cookies:{" "}
          <a
            onClick={(e) => {
              window.Apploud.cookieBar.$emit("show:settings");
            }}
          >
            Nastavení cookies
          </a>
          .
        </p>
        <h2 className="login-page__h2">SEZNAM SOUBORŮ COOKIES</h2>
        <h3>Technické cookies</h3>
        <table>
          <tr>
            <th>NÁZEV</th>
            <th>POPIS</th>
            <th>DOBA PLATNOSTI</th>
          </tr>
          <tr>
            <td>APPLOUD CONSENT</td>
            <td>Sleduje uživatelovo nastavení ohledně preferencí cookies</td>
            <td>365 dní</td>
          </tr>
          <tr>
            <td>consentId</td>
            <td>Jedinečné označení souhlasu</td>
            <td>365 dní</td>
          </tr>
        </table>
        <p className="login-page__p">
          Technické cookies jsou na webových stránkách Banka využívány k jejich
          správnému fungování. Tento typ cookies neukládá žádné Vaše osobní
          údaje, podle kterých by Vás bylo možné identifikovat. Z důvodu jejich
          nezbytnosti není však možné technické cookies odmítnout.
        </p>
        <h3>Statistické cookies</h3>
        <table className="static-cookies">
          <tr>
            <td>NÁZEV</td>
            <td>_GA</td>
          </tr>
          <tr>
            <td>POPIS</td>
            <td>
              Definuje unikátní ID pro uživatele ke sledování webové analytiky.
            </td>
          </tr>
          <tr>
            <td>POSKYTOVATEL</td>
            <td>Google Inc.</td>
          </tr>
          <tr>
            <td>DOBA PLATNOSTI</td>
            <td>2 roky</td>
          </tr>
        </table>
        <p className="login-page__p">
          Statistické cookies slouží k zjišťování návštěvnosti stránek a zejména
          částí stránek, které jsou nejoblíbenější. Prostřednictvím těchto
          informací Banka upravuje stránky k co největší spokojenosti uživatelů.
          Pokud k těmto cookies neudělíte souhlas, Banka není oprávněna je
          zpracovávat, tudíž Banka bude mít vylepšování stránek ztížené.
        </p>

        <h2 className="login-page__h2">PŘEHLED TŘETÍCH STRAN</h2>
        <p className="login-page__p">
          Přehled třetích stran a informací o ochraně osobních údajů:
        </p>
        <ul>
          <li>
            <a
              href="https://business.safety.google/compliance/"
              target="_blank"
              rel="noreferrer"
            >
              Google
            </a>
          </li>
        </ul>
        <Button className="button-next" onClick={() => window.history.go(-1)}>Zpět</Button>
      </div>
    </div>
  );
}

export default Cookies;
