export function validateQuestion( question ) {
	var atLeastOne = false;
	var errorIds = [];

	//	check subquestions
	var subquestErrorIds = [];
	if( question.subquestions && question.subquestions.length > 0 ) {
console.log('validateQuestion subQerrors:', subquestErrorIds);
		if( question.subversionIdentificator == "k2_3" ) {
			var onecdone = false;
			for( var q in question.subquestions ) {
				var list = 
					validateQuestion( question.subquestions[ q ] ) ;
				if ( list.length == 0) {
					onecdone = true;
				}
			}
			if (!onecdone) {
				for( var q in question.subquestions ) {
					subquestErrorIds = subquestErrorIds.concat(
						validateQuestion( question.subquestions[ q ] )
					);
				}
			}
		} else {
			for( var q in question.subquestions ) {
				subquestErrorIds = subquestErrorIds.concat(
					validateQuestion( question.subquestions[ q ] )
				);
			}
		}
	}

	//	check answers
	if( question.subversionIdentificator != "k2_3" ) {
		if( question.answers && question.answers.length > 0 ) {
			for( var a in question.answers ) {
				var answerId = question.answers[ a ].answerId;
				var validateResult = validateAnswer( question.answers[ a ] );
				if( validateResult === "OK" ) {
					atLeastOne = true;
				} else if( validateResult === "BLANK" ) {
					// nic
				} else {
					// původně jsme vraceli mateřské answerId, validateResult bude ale nejblíže erroru
					errorIds.push( validateResult );
				}
			}
		}
	} else atLeastOne = true;
	// console.log('validateQuestion errors:', errorIds);
	if( question?.subquestions?.length ) {
		// console.log('validateQuestion subQerrors:', subquestErrorIds);
	}
	errorIds = errorIds.concat( subquestErrorIds );
	// console.log('validateQuestion errors:', errorIds);
	if( errorIds.length ) {
		return errorIds;
	}
	if( !atLeastOne ) {
		return question.answers.map( ( a ) => a.answerId );
	}
	return [];
}

/**
 * @returns {"OK"|"BLANK"|"ID_otázky_co_je_špatně"|}
 */
function validateAnswer( answer ) {
	var answerInput = document.getElementById( answer.answerId );
	if( !answerInput ) {
		console.error( answer.answerId, "not found" );
	}
	switch ( answer.answerType ) {
		case "Radio":
		case "Checkbox":
		case "TableCol":
			if( answerInput && answerInput.checked ) {
				// je čeklý
				if( answer.subAnswers && answer.subAnswers.length > 0 ) {
					// ... a má subanswery
					var hasAtLeastOne = false;
					for( var s in answer.subAnswers ) {
						let subAnswer = answer.subAnswers[ s ];
						let subResult = validateAnswer( subAnswer );
						if( subResult === "OK" ) {
							hasAtLeastOne = true;
						} else if( subResult === "BLANK" ) {
							// nic
						} else {
							return subAnswer.answerId;
							// return "ERROR";
						}
					}
					// console.log(subAnswerErrorIds);
					if( hasAtLeastOne ) {
						// Měl nějakou vybranou
						return "OK";
					} else {
						// nic vybraného = chyba
						return answer.answerId;
					}
				} else {
					// čeklý a nemá subanswery
					return "OK";
				}
			}
			// není čeklý
			return "BLANK";
			break;
		case "String":
			// String je povinný kdykoli je zobrazený; nemůže být "blank"
			if( answerInput.value.trim().length > 1 ) {
				return "OK";
			} else {
				return answer.answerId;
			}
			break;
		case "RadioString":
		case "CheckboxString": {
			if( answerInput.checked ) {
				let textSubInput = document.getElementById( "text_" + answer.answerId );
				if( textSubInput && textSubInput.value.trim().length > 1 ) {
					return "OK";
				}
				return answer.answerId;
				// return "ERROR";
			}
			return "BLANK";
			break;
		}
		case "CheckboxDropdown":
			if( answerInput.checked ) {
				if(
					// "stát"
					document.getElementById( "select_" + answer.answerId ) &&
					document.getElementById( "select_" + answer.answerId ).value.trim() !==
					"" &&
					// "dič"
					document.getElementById( "text_" + answer.answerId ) &&
					document.getElementById( "text_" + answer.answerId ).value.trim()
					.length > 1
				) {
					return "OK";
				} else {
					return answer.answerId;
				}
			} else {
				return "BLANK";
			}
			break;
		default:
			return answer.answerId;
			// return "ERROR";
	}
	// switch výše musí mít returny pokryté vše
	throw Error( "wot" );
}