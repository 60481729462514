import React, { useState } from "react";
import InputRadio from "./InputRadio";

function InputRadioWrapper(props) {
  const [checked, setChecked] = useState(false);
  const handleOnChange = (e) => {
    setChecked(e.target.checked);
    // console.log(e.target.checked);
    //props.handleEct();

    for (var i in window.ect) {
      if (window.ect[i] != myRef) {
        window.ect[i]();
      }
    }

    if (props.passSubAnswers) {
      if (!window.ect) window.ect = [];
      window.ect.push(myRef);
    }

    props.onChange(e);
  };

  const handleDeselect = (e) => {
    // console.log("deselect");
    setChecked(false);

    var i = window.ect.indexOf(myRef);
    if (i > -1) window.ect.splice(i, 1);
  };

  var myRef = handleDeselect;

  return (
    <div>
      <div className="wrapper-radio">
        <InputRadio
          name={props.name}
          value={props.value}
          id={props.id}
          // checked={checked}
          checked={props.checked}
          onChange={handleOnChange}
        ></InputRadio>
        <span className="custom-radio"></span>
        <label className="label-radio">{props.children}</label>
      </div>
      {/* {console.log(checked)} */}
      {checked && props.passSubAnswers || props.value === "h2a1" ? (
        <div className={`subanswers ${checked ? "" : "h2disable"}`}>
          {props.passSubAnswers.map(a => { return { ...a, checkOverride: checked } }).map(props.renderAnswer)}{" "}
        </div>
      ) : null}
    </div>
  );
}
export default InputRadioWrapper;
