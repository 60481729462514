import React, { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  ROUTE_DOTAZNIK,
  ROUTE_EXPIRED,
  ROUTE_INVALID,
  ROUTE_COOKIES,
  ROUTE_TECHNICALISSUE,
  ROUTE_MAINTENANCE,
} from "./routes";
import LoginPage from "./stage/loginPage/LoginPage";
import ExpiredPage from "./stage/expiredPage/ExpiredPage";
import InvesticniDotaznik from "./stage/questionnaire/InvesticniDotaznik";
import Cookies from "./stage/cookies/Cookies";
import { LastAnswersContext } from "./stage/loginPage/LastQuestionsContext";

function App() {
  let test_api_host = "https://test.kava.jtfg.com:443";
  if (document.location.hostname.includes("id.jtbank")) {
    window["INVEST-FORM-API-BASE"] = "/invest-form-rest-webapi/rest/web-service/v1.0/";
  } else if (document.location.hostname.includes("ref")) {
    window["INVEST-FORM-API-BASE"] = "/invest-form-rest-webapi-ref/rest/web-service/v1.0/";
  } else if (document.location.href.includes("akc")) {
    window["INVEST-FORM-API-BASE"] = test_api_host + "/invest-form-rest-webapi-akc/rest/web-service/v1.0/";
  } else {
    window["INVEST-FORM-API-BASE"] = test_api_host + "/invest-form-rest-webapi-test/rest/web-service/v1.0/";
    // window["INVEST-FORM-API-BASE"] = test_api_host + "/invest-form-rest-webapi-dev/rest/web-service/v1.0/";
  }
  const [loading, setLoading] = useState(false);

  const storageSessionIdKey = "sessionID";
  const location = window.location.search;
  const urlParams = new URLSearchParams(location);

  const tokenID = urlParams.get("id") || sessionStorage.getItem("tokenID") || "";
  sessionStorage.setItem("tokenID", tokenID);
  const [tokenUrl, setTokenUrl] = useState(tokenID);

  const sessionID = sessionStorage.getItem(storageSessionIdKey) || "";
  const [sessionId, setSessionId] = useState(sessionID);

  const [visibleStep2, setVisibleStep2] = useState(false);

  function saveSessionId(id) {
    sessionStorage.setItem(storageSessionIdKey, id);
    setSessionId(id);
  }
  const [LastAnswers, setLastAnswers] = useState([]);

  function showLogInTest(fetchName, data) {
    if (!document.location.hostname.includes("id.jtbank")) {
      console.log(fetchName, data);
    }
  }

  const navigate = useNavigate();
  const [dateTo, setDateTo] = useState("");
  function getDateFromMilliseconds(ms) {
    const date = new Date(ms);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}. ${month}. ${year}`;
  }

  function IsMaintanance() {
    fetch(window["INVEST-FORM-API-BASE"] + "get-display-message-list", {
      method: "POST",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        showLogInTest("is maintenance", data);
        if (data.responseStatus === "OK" && data.result.length !== 0) {
          const milliseconds = data.result[0].showTo;
          setDateTo(getDateFromMilliseconds(milliseconds));
          navigate("/maintenance");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    IsMaintanance();
  }, []);

  return (
    <div>
      <LastAnswersContext.Provider value={LastAnswers}>
        <Routes>
          <Route
            path="/"
            element={
              <LoginPage
                tokenUrl={tokenUrl}
                sessionId={sessionId}
                saveSessionId={saveSessionId}
                setLastAnswers={setLastAnswers}
                loading={loading}
                setLoading={setLoading}
                showLogInTest={showLogInTest}
                visibleStep2={visibleStep2}
                setVisibleStep2={setVisibleStep2}
              />
            }
          ></Route>
          <Route
            path={ROUTE_DOTAZNIK}
            element={
              <InvesticniDotaznik
                tokenUrl={tokenUrl}
                sessionId={sessionId}
                setLastAnswers={setLastAnswers}
                loading={loading}
                setLoading={setLoading}
                showLogInTest={showLogInTest}
              />
            }
          ></Route>
          <Route path={ROUTE_EXPIRED} element={<ExpiredPage reason="expired" />} />
          <Route path={ROUTE_INVALID} element={<ExpiredPage reason="invalid" />} />
          <Route path={ROUTE_TECHNICALISSUE} element={<ExpiredPage reason="technical-issue" />} />
          <Route path={ROUTE_MAINTENANCE} element={<ExpiredPage reason="maintenance" dateTo={dateTo} />} />
          <Route path={ROUTE_COOKIES} element={<Cookies tokenUrl={tokenUrl} sessionId={sessionId} />} />
          <Route path={"/investicni-dotaznik-akc/cookies"} element={<Cookies tokenUrl={tokenUrl} sessionId={sessionId} />} />
          <Route path={"/investicni-dotaznik-akc/cookies/"} element={<Cookies tokenUrl={tokenUrl} sessionId={sessionId} />} />
          <Route path={"/investicni-dotaznik/cookies"} element={<Cookies tokenUrl={tokenUrl} sessionId={sessionId} />} />
          <Route path="*" element={<ExpiredPage reason="unknown" />} />
        </Routes>
      </LastAnswersContext.Provider>
    </div>
  );
}

export default App;
