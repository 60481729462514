import React from "react";
import { Link } from "react-router-dom";
import "./expired-page.css";
import "./../stages.css";
import Banner from "../../components/Banner";

function ExpiredPage({ reason, dateTo }) {
  return (
    <div className="page-with-banner">
      <Banner />
      <div className="content">
        <h3 className="h3--id font12">investiční dotazník</h3>
        <h2 className="expired__h2 font14">
          <svg
            className="triangle"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.0933 19.25L21.5263 18.5L12.433 2.75L11.567 2.75L2.47372 18.5L2.90673 19.25H21.0933Z"
              stroke="#262626"
              strokeLinejoin="bevel"
            />
            <line x1="12.25" y1="14.25" x2="12.25" y2="6.75" stroke="#262626" />
            <line x1="12.25" y1="17.25" x2="12.25" y2="15.75" stroke="#262626" />
          </svg>
          {(() => {
            switch (reason) {
              case "expired":
                return <span>Platnost tohoto odkazu již bohužel vypršela.</span>;
              case "technical-issue":
                return <span>Aktuálně evidujeme technický výpadek webu.</span>;
              case "maintenance":
                return <span>Aktuálně probíhá plánovaná odstávka webu.</span>;
              default:
                window.history.replaceState(null, "", "/invalid")
                return (
                  <span>
                    Zadaná URL adresa není platná nebo byl proces aktualizace investičního dotazníku dokončen.
                  </span>
                );
            }
          })()}
        </h2>
        <p className="expired__p font14-400">
          {(() => {
            switch (reason) {
              case "expired":
                return <span>Rádi Vám vygenerujeme nový, abyste mohli svůj investiční dotazník vyplnit.</span>;
              case "technical-issue":
                return (
                  <span>
                    Omlouváme se za dočasnou nedostupnost webu. O problému jsme informování a intenzivně pracujeme na jeho odstranění.
                  </span>
                );
              case "maintenance":
                return (
                  <span>
                    Omlouváme se za dočasnou nedostupnost webu. Právě pracujeme na jeho vylepšení.
                    <br />
                    Web bude opět dostupný <span style={{fontWeight: "bold"}}>{dateTo}</span>.
                  </span>
                );
              default:
                return (
                  <span>Pokud si přejete investiční dotazník aktualizovat rádi Vám vygenerujeme novou adresu.</span>
                );
            }
          })()}
        </p>
        <p className="expired__p font-14-400">
          {(() => {
            switch (reason) {
              case "expired":
                return (
                  <span>
                    Obraťte se na svého osobního bankéře, nebo na naše kolegyně a kolegy na Klientské lince{" "}
                    <a href="tel:+420800707606" className="link-dark">
                      800&nbsp;707&nbsp;606
                    </a>
                    .<br></br>
                    <span className="expired__p--dark">
                      Jsou Vám k&nbsp;dispozici ve&nbsp;všední dny od&nbsp;8 do&nbsp;20&nbsp;hodin a o&nbsp;víkendu
                      od&nbsp;10 do&nbsp;18&nbsp;hodin.
                    </span>
                  </span>
                );
              case "technical-issue":
                return (
                    <span>Děkujeme za pochopení J&T Banka</span>
                );
              case "maintenance":
                return <span>Děkujeme za pochopení J&T Banka</span>;
              default:
                return (
                  <span>
                    Obraťte se na svého osobního bankéře, nebo na naše kolegyně a kolegy na Klientské lince{" "}
                    <a href="tel:+420800707606" className="link-dark">
                      800&nbsp;707&nbsp;606
                    </a>
                    .<br></br>
                    <span className="expired__p--dark">
                      Jsou Vám k&nbsp;dispozici ve&nbsp;všední dny od&nbsp;8 do&nbsp;20&nbsp;hodin a o&nbsp;víkendu
                      od&nbsp;10 do&nbsp;18&nbsp;hodin.
                    </span>
                  </span>
                );
            }
          })()}
        </p>
        <Link to={"/cookies"} className="cookies-link">
          Informace o cookies
        </Link>
      </div>
    </div>
  );
}
export default ExpiredPage;
