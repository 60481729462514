import React, { useState } from 'react';
import InputRadio from './InputRadio';
import Input from './Input';

function InputRadioStringWrapper(props) {
  const [checked, setChecked] = useState(false);
  const handleOnChange = (e) => {
    // console.log('Radiostring:'+e.target.id);
    setChecked(e.target.checked);
    //props.registerEct(myRef);
    if ( !window.ect ) window.ect = [];
    window.ect.push( myRef );
    props.onChange(e);
  };

  const handleDeselect = (e) => {
	// console.log('deselect');
	setChecked(false);

	var i = window.ect.indexOf( myRef );
	if ( i > -1) window.ect.splice(i, 1);
  };

  const myRef = handleDeselect;
  //myRef.current = this;

  return (
    <div>
      <div className='wrapper-radio'>
        <InputRadio
          name={props.name}
          value={props.value}
          onChange={handleOnChange}
          checked={checked}
          id={props.id}
        ></InputRadio>
        <span className='custom-radio'></span>
        <label className='label-radio'>{props.children}</label>
      </div>
      {checked ? <Input className="text-showed" id={'text_'+props.id} onChange={props.onChange}></Input> : ""}
    </div>
  )
}
export default InputRadioStringWrapper