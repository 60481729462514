import React, { useEffect, useState } from "react";
import Button from "./Button";

/**
 * @param {{ handleResend: any, handleExpire: any, tokenUrl: any, sessionId: any, setInputSms: any }} props
*/
function CountDownTicker({ handleResend, handleExpire, tokenUrl, sessionId, setInputSms }) {
  // modal
  const [showModal, setShowModal] = useState(false);
  if (showModal === true) {
    document.body.classList.add("open-modal");
  } else {
    document.body.classList.remove("open-modal");
  }
  //#region potenciálně obecné
  const MilisecondsInSecond = 1000;
  const SecondsInMinute = 60;
  const MilisecondsInMinute = SecondsInMinute * MilisecondsInSecond;

  const totalDurationMS = 5.0 * MilisecondsInMinute;
  const allowRefreshAt = 4.0 * MilisecondsInMinute;
  const allowRefreshCodeAt = 4.5 * MilisecondsInMinute;

  function displayTime(ms) {
    if (!ms || ms < 0) {
      return `0:00 `;
    }
    const secondsTotal = Math.floor(ms / MilisecondsInSecond);
    const m = Math.floor(secondsTotal / SecondsInMinute);
    // const mm = String(m).padStart(2, '0');
    const s = secondsTotal % SecondsInMinute;
    const ss = String(s).padStart(2, "0");
    return (
      <>
        {m}:{ss}
      </>
    );
  }
  //#endregion

  const [end, setEnd] = useState(Date.now() + totalDurationMS);
  const [remainingMS, setRemainingMS] = useState(end - Date.now());
  const allowRefresh = allowRefreshAt >= end - Date.now();
  const allowRefreshCode = allowRefreshCodeAt >= end - Date.now();
  //if ( (totalDurationMS >= end - Date.now()) ) handleExpire(true);
  useEffect(function () {
    if (end < Date.now()) {
      handleExpire(false);
      return;
    }
    const t = setTimeout(function () {
      setRemainingMS(end - Date.now());
    }, 1000);
    return function () {
      clearTimeout(t);
    };
  });

  let turn = 1 - remainingMS / totalDurationMS;
  const styleConic = {
    backgroundImage:
      "conic-gradient(from 0.25turn, #D9D9D9 0" + turn + "turn, transparent 0)",
  };

  const endSession = () => {
    fetch(
      window['INVEST-FORM-API-BASE'] + "otp-not-delivered",
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          token: tokenUrl,
          sessionId: sessionId,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setTimeout(() => {
          window.location.href = "https://www.jtbank.cz/";
        }, 500);
      });
  };

  return (
    <>
      <span className="conic" style={styleConic}></span>
      <span className="login-page__span--counter">
        {displayTime(remainingMS)}
      </span>
      <span className="login-page__span--counter">min.</span>
      <Button
        className="send-code"
        disabled={!allowRefreshCode}
        onClick={function () {
          setEnd(Date.now() + totalDurationMS);
          handleResend();
          setInputSms("");
        }}
      >
        <PhoneImg />
        Poslat kód znovu
      </Button>
      <Button
        className="no-sent-code"
        disabled={!allowRefresh}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Nedorazil SMS kód?
      </Button>
      {showModal && <Modal setShowModal={setShowModal} endSession={endSession} />}
    </>
  );
}

function Modal ({ setShowModal, endSession }) {
  return <div className="modal-bg">
    <div className="modal">
      <div className="modal-header">
        <Button
          onClick={() => {
            setShowModal(false);
          }}
        >
          <CloseImg />
        </Button>
      </div>
      <p>
        Bohužel bez ověření nemůžeme pokračovat dále. Kontaktujte prosím
        naše Klientské centrum na čísle{" "}
        <a href="tel:+420800707606">800 707 606</a> nebo na adrese
        {" "}<a href="mailto:jtbank@jtbank.cz">jtbank@jtbank.cz</a>, kde vám
        pomohou problém vyřešit.
      </p>
      <div className="modal-footer">
        <Button
          onClick={endSession}
          className="button button-next button-link m0"
        >
          {/* <a
                  href="https://www.jtbank.cz/"
                  className="button button-next button-link m0"
                > */}
          Odejít
          {/* </a> */}
        </Button>
      </div>
    </div>
  </div>
}

function PhoneImg () {
  return <svg
    className="phone-img"
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33301 1.3335V2.8335C3.33301 3.10933 3.51967 3.3335 3.74967 3.3335H6.24968C6.47968 3.3335 6.66634 3.10933 6.66634 2.8335V1.3335"
      stroke="#081E1D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.06641 12.5837H5.93307"
      stroke="#00776A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.49967 1.3335H7.49967C8.42051 1.3335 9.16634 2.07933 9.16634 3.00016V13.0002C9.16634 13.921 8.42051 14.6668 7.49967 14.6668H2.49967C1.57884 14.6668 0.833008 13.921 0.833008 13.0002V3.00016C0.833008 2.07933 1.57884 1.3335 2.49967 1.3335Z"
      stroke="#00776A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
}

function CloseImg () {
  return <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="0.853553"
      y1="1.2944"
      x2="15.5485"
      y2="15.9894"
      stroke="white"
    />
    <line
      x1="0.440392"
      y1="15.8413"
      x2="15.1354"
      y2="1.14629"
      stroke="white"
    />
  </svg>
}
export default CountDownTicker;
