import React, { useContext } from 'react';
import { LastAnswersContext } from '../stage/loginPage/LastQuestionsContext';

function Input(props) {
  const lastAnswers = useContext(LastAnswersContext);
  let _value = props.value
  if (!_value) {
    lastAnswers.some(a => {
      // props.id je vždy prefixované "text_" (?)
      if ( ('text_' + a.answers.answerId) === props.id) {
        _value = a.answers.note;
        return true
      }
      return false
    })
  }

  return (
    <input
      type={props.type || "text"}
      className={`text ${props.className || ""}`}
      placeholder={props.placeholder || ""}
      id={props.id || ""}
      onChange={props.onChange}
      onInput={props.onInput}
      value={_value}
      inputMode={props.inputmode || ""}
      pattern={props.pattern || ""}
      maxLength={props.maxLength || ""}
    ></input>
  )
}
export default Input