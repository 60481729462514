import React, { useContext, useState } from "react";
import Input from "./Input";
import Label from "./Label";
import Checkbox from "./Checkbox";
import { LastAnswersContext } from "../stage/loginPage/LastQuestionsContext";

function InputCheckboxDropdown(props) {
  const [checked, setChecked] = useState(false);
  const handleOnChange = (e) => {
    setChecked(e.target.checked);
    props.onChange();
  };
  const lastAnswers = useContext(LastAnswersContext);
  let _checked = props.checked;

  if (!_checked && lastAnswers.some((a) => a.answers.answerId === props.id)) {
    _checked = true;
  }

  return (
    <div>
      <Checkbox
        name={props.name}
        checked={_checked}
        onChange={handleOnChange}
        value={props.value}
        id={props.id}
        key={props.id}
        >
        {props.children}
      </Checkbox>
      {checked ? (
        <Label className="label-string text-showed">Státu</Label>
        ) : null}
      {checked ? (
        <select
        // key={props.id}
          className="text text-showed"
          id={"select_" + props.id}
          onChange={props.onChange}
        >
          {props.optionValue.map((item) => {
            if (item.answerType === "DropdownValue") {
              return (
                <option
                  value={item.answerId}
                  key={item.answerId}
                  id={item.answerId}
                >
                  {item.textCz}
                </option>
              );
            }
          })}
        </select>
      ) : null}
      {checked
        ? props.optionValue.map((line) =>
          line.answerType === "String" ? (
            <>
              <Label className="label-string text-showed text-showed2"
                key={"label_ " + props.id}
              >
                {line.textCz}
              </Label>
              <input
                className=" text text-showed text-showed2"
                id={"text_" + props.id}
                onChange={props.onChange}
                key={"text_" + props.id}
              ></input>
            </>
          ) : null
        )
        : null}
    </div>
  );
}
export default InputCheckboxDropdown;
