
import React from 'react';
import "./../css/colors.css";
import "./../css/fonts.css";

function Banner () {
  return (
    <div className="banner">
      <svg width="170" height="24" viewBox="0 0 110 16" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" className="banner-svg">
        <path d="M23.3027 4.92771H26.2596V15.6381H31.4432V4.92771H34.3879V0.337524H23.3027V4.92771Z" fill="#ffffff" />
        <path d="M3.65044 11.0492H0V15.6394H5.2323C5.67035 15.6394 5.93805 15.6029 5.93805 15.6029C7.54425 15.3601 8.7854 14.0121 8.83407 12.3485V0.338745H3.63827V11.0492H3.65044Z" fill="#ffffff" />
        <path d="M22.5003 12.238C22.6463 11.5459 22.6828 10.8051 22.6828 10.0887C22.6828 9.68794 22.6706 9.29935 22.6463 8.88648H21.0523C21.0523 9.22649 21.1131 9.60293 21.0888 9.95509C21.0644 10.2951 21.0523 10.623 21.0279 10.963L15.0655 5.97207C14.2624 5.30419 13.9947 4.9156 13.9947 3.83485C13.9947 1.81905 15.2967 1.75833 16.9516 1.75833C19.2392 1.75833 19.5191 2.14692 19.5434 4.07771V4.9156H21.2835V4.07771C21.2835 0.859727 19.7259 0.228271 16.9516 0.228271C13.9461 0.228271 12.2547 0.823297 12.2547 3.83485C12.2547 5.54706 12.6441 6.02065 13.6419 6.85854C11.6706 7.40499 10.5146 7.30784 10.5146 11.0237C10.5146 15.796 12.9483 15.7717 16.3432 15.7717C18.789 15.7717 21.1861 15.7717 22.0622 13.7681L24.3377 15.6382L25.2746 14.436L22.5003 12.238ZM16.3554 14.2417C12.7414 14.2417 12.2669 14.0231 12.2669 11.0358C12.2669 8.60718 12.4859 8.3036 14.737 7.79358L20.7237 12.7116C20.3587 14.3753 18.0102 14.2417 16.3554 14.2417Z" fill="#ffffff" />
        <path d="M48.5992 7.61145C50.035 7.05286 50.3635 5.94781 50.3514 4.41775C50.3514 3.09413 50.181 2.07409 49.6456 1.38192C49.0981 0.677602 48.1854 0.349731 46.7496 0.349731H38.9863V15.6503H47.1512C48.441 15.6503 49.3658 15.2618 49.962 14.5332C50.5461 13.8046 50.8016 12.7238 50.8016 11.3516C50.8138 9.5301 50.4852 8.03647 48.5992 7.61145ZM40.8724 2.01337H45.9708C47.7717 2.03766 48.4653 2.18338 48.4775 4.18703C48.4531 6.53069 47.8447 6.90714 45.7883 6.93142H40.8724V2.01337ZM46.2264 13.9624H40.8724V8.61935H46.5671C48.6722 8.64363 48.9034 9.54224 48.9277 11.5095C48.8912 13.7924 48.0881 13.9381 46.2264 13.9624Z" fill="#ffffff" />
        <path d="M59.5859 0.337524H57.0549L51.4697 15.6381H53.441L54.6091 12.4323H62.1534L63.3459 15.6381H65.3049L59.5859 0.337524ZM55.1445 10.8537L58.3691 2.07402L61.6301 10.8537H55.1445Z" fill="#ffffff" />
        <path d="M103.903 0.337828H101.36L95.8115 15.5413L88.0483 7.73312L95.0571 0.325684H92.587L86.4056 6.9438H85.0306V0.325684H83.1445V15.6263H85.0306V8.61958H86.4056L93.3414 15.6263H97.7463L98.9144 12.4205H106.459L107.651 15.6263H109.598L103.903 0.337828ZM99.4498 10.854L102.674 2.07432L105.935 10.854H99.4498Z" fill="#ffffff" />
        <path d="M77.973 14.0231L69.577 0.337524H66.6201V15.6381H68.494V2.04973L76.9266 15.6381H79.8591V0.337524H77.973V14.0231Z" fill="#ffffff" />
      </svg>
      <p className='banner__p font16'>Růst. Společně.</p>
    </div>
  )
}

export default Banner