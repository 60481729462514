import React from 'react';

function Label(props) {

// class: label-radio/label-text/

  return (
      // <div className='wrapper-text-label'>
        <label className={props.className}>{props.children}</label>
      // </div>
  )
}
export default Label