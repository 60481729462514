import React, { useContext, useState } from "react";
import Checkbox from "./Checkbox";
import { handleExceptions } from "../functions/exceptions";
import { LastAnswersContext } from "../stage/loginPage/LastQuestionsContext";

function InputCheckbox(props) {
  const [checked, setChecked] = useState(props.checkOverride);
  const [value, setValue] = useState("");
  const handleOnChange = (e) => {
    setChecked(e.target.checked);
    handleExceptions(e.target.name);
    props.onChange(e);
    if (checked === false) {
      setValue(e.target.value);
    } else {
      setValue("");
    }
  };
  const lastAnswers = useContext(LastAnswersContext);
  let _checked = props.checked;

  if (
    !_checked &&
    lastAnswers.some(a => a.answers.answerId === props.id)
  ) {
    _checked = true
  }

  // console.log('XXX', document.getElementById(props.id), props.checkOverride); 
  if (typeof props.checkOverride == 'boolean') {
    if (props.checkOverride === false) {
      _checked = false
    }
  }

  return (
    <div>
      <Checkbox
        checked={_checked}
        name={props.name}
        onChange={handleOnChange}
        value={props.value}
        id={props.id}
      >{props.children}</Checkbox>
        {checked && props.passSubAnswers ? (
          <div className="subanswers">
            {props.passSubAnswers.map(props.renderAnswer)}
          </div>
      ) : null}
      {/* {console.log( props.checked, props.passSubAnswers)} */}
    </div>
  );
}
export default InputCheckbox;
