import React from 'react';

function Button(props) {

  // className string + {classa, co tomu da BtnNext/Prev, pokud by mu nic dalsiho nedavaly, tak prazdny string}
  return (
    <button className={ `button ${props.className || ""}` } type='button' name={props.name} onClick={() => props.onClick()} disabled={props.disabled}>{props.children}</button>
  )
}

export default Button